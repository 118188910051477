import Link from 'next/link';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

import Header from '@components/layout/Header';

export default function Page404() {
  const { t: tt } = useTranslation('not_found');

  return (
    <>
      <Header />

      <main className="h-screen flex flex-col justify-center items-center">
        <h1>{tt('title')}</h1>

        <Link href={'/ai-girls'} className="!text-white/60">
          {tt('explore_ai_girls')}
        </Link>
      </main>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['header', 'not_found'])),
    },
  };
}
